// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-oi-wiki-src-pages-404-tsx": () => import("./../../../node_modules/gatsby-theme-oi-wiki/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-oi-wiki-src-pages-404-tsx" */),
  "component---node-modules-gatsby-theme-oi-wiki-src-pages-pages-tsx": () => import("./../../../node_modules/gatsby-theme-oi-wiki/src/pages/pages.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-oi-wiki-src-pages-pages-tsx" */),
  "component---node-modules-gatsby-theme-oi-wiki-src-pages-play-tsx": () => import("./../../../node_modules/gatsby-theme-oi-wiki/src/pages/play.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-oi-wiki-src-pages-play-tsx" */),
  "component---node-modules-gatsby-theme-oi-wiki-src-pages-settings-tsx": () => import("./../../../node_modules/gatsby-theme-oi-wiki/src/pages/settings.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-oi-wiki-src-pages-settings-tsx" */),
  "component---node-modules-gatsby-theme-oi-wiki-src-pages-tags-tsx": () => import("./../../../node_modules/gatsby-theme-oi-wiki/src/pages/tags.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-oi-wiki-src-pages-tags-tsx" */),
  "component---node-modules-gatsby-theme-oi-wiki-src-templates-changelog-js": () => import("./../../../node_modules/gatsby-theme-oi-wiki/src/templates/changelog.js" /* webpackChunkName: "component---node-modules-gatsby-theme-oi-wiki-src-templates-changelog-js" */),
  "component---node-modules-gatsby-theme-oi-wiki-src-templates-doc-js": () => import("./../../../node_modules/gatsby-theme-oi-wiki/src/templates/doc.js" /* webpackChunkName: "component---node-modules-gatsby-theme-oi-wiki-src-templates-doc-js" */),
  "component---node-modules-gatsby-theme-oi-wiki-src-templates-tags-js": () => import("./../../../node_modules/gatsby-theme-oi-wiki/src/templates/tags.js" /* webpackChunkName: "component---node-modules-gatsby-theme-oi-wiki-src-templates-tags-js" */)
}

